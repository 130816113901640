<template>
  <div class="page meditations">
    <div class="section-header">
      <h1>Meditations</h1>
      <h2 class="section-tagline rot2">Breathe in. Watch out.</h2>
      <p class="section-intro">
        The meditations could be used at weddings, picnics, concerts, sports
        events, raves and retreats, or you might enjoy them while lying in your
        bathtub (empty or full).
      </p>
    </div>
    <!-- MEDITATIONS -->
    <div class="meditations-content">
      <MeditationItem
        title="Laguardia"
        slug="laguardia"
        description="Detailed Blessings for Times of Transit"
        readBy="Seth Kanor"
        heroImgURL="/meditations-audio/meditation_01-laguardia.jpg"
        audioLoc="/audio/laguardia.wav"
      />
      <MeditationItem
        title="Hello Virus"
        slug="hello-virus"
        description="Imagine World Peace"
        readBy="Jessica Almasy"
        heroImgURL="/meditations-audio/meditation_02-dear_virus.jpg"
        audioLoc="/audio/hello_virus.mp3"
      />
      <MeditationItem
        title="Herb"
        slug="herb"
        description="Your Life is a Bed of Weeds"
        readBy="Seth Kanor"
        heroImgURL="/meditations-audio/meditation_03-herb.jpg"
        audioLoc="/audio/herb.wav"
      />
      <MeditationItem
        title="Human Too"
        slug="human-too"
        description="You Sense the Times of Your Life"
        readBy="Jane Cramer"
        heroImgURL="/meditations-audio/meditation_04-human_too.jpg"
        audioLoc="/audio/human_too.mp3"
      />
      <MeditationItem
        title="Word Gathering"
        slug="word-gathering"
        description="You Discover Elemental Conversations"
        readBy="Jessica Almasy"
        heroImgURL="/meditations-audio/meditation_05-word_gathering.jpg"
        audioLoc="/audio/word_gathering.mp3"
      />
      <MeditationItem
        title="A Puddle of Ducks"
        slug="a-puddle-of-ducks"
        description="The Unexpected Transpoetics of Groups"
        readBy="Eliza Foss"
        heroImgURL="/meditations-audio/meditation_06-puddle_of_ducks.jpg"
        audioLoc="/audio/a_puddle_of_ducks.wav"
      />
      <MeditationItem
        title="The Hydrosphere incantation"
        slug="the-hydrosphere-incantation"
        description="You Flow, You Freeze, You Float"
        readBy="Eliza Foss and Seth Kanor"
        heroImgURL="/meditations-audio/meditation_07-the_hydrosphere_incantation.jpg"
        audioLoc="/audio/the_hydrosphere_incantation.wav"
      />
      <p>
        <small>
          Cover images by
          <a href="https://unsplash.com/@dmey503" target="_blank">Dan Meyers</a
          >, <a href="https://unsplash.com/@cdc" target="_blank">CDC</a>,
          <a href="https://unsplash.com/@wackeltin_meem" target="_blank"
            >Valentin Müller</a
          >,
          <a href="https://unsplash.com/@hazardos" target="_blank"
            >Hassan Ouajbir</a
          >,
          <a href="https://unsplash.com/@teapowered" target="_blank"
            >Patrick Robert Doyle</a
          >
          and
          <a href="https://unsplash.com/@steve_huntington" target="_blank"
            >Steve Huntington</a
          >
          via <a href="https://unsplash.com/" target="_blank">unsplash</a>.
        </small>
      </p>
    </div>
  </div>
</template>

<script>
import MeditationItem from "@/components/MeditationItem.vue";
// import content from "!raw-loader!@/page-contents/shows-and-events.md";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Meditations`,
      description: `BREATHE IN. WATCH OUT.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/installations/`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "Meditations",
  components: {
    MeditationItem,
  },
};
</script>

<style lang="scss" scoped>
.meditations-content {
  padding: 2rem;
  max-width: 750px;
  margin: 0 auto;
}
</style>
